import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/projects.module.css"
import { Helmet } from "react-helmet"
export default function Projects() {
  return (
    <Layout>
      <div className={styles.portfolio}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio Projects</title>
          <link rel="canonical" href="http://mkrilanovich.com/projects" />
        </Helmet>
        <h2>Portfolio - test</h2>
        <h3>Projects & Websites I've Created</h3>
      </div>
    </Layout>
  )
}
